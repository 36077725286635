import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  RDS_DIALOG_DATA,
  RdsButtonModule,
  RdsDialogModule,
  RdsDialogRef,
  RdsFormFieldModule,
  RdsIconComponent,
} from '@rds/angular-components';

export enum BookingRequestActionModalEnum {
  Confirm = 'confirm',
  Reject = 'reject',
  Cancel = 'cancel',
}

@Component({
  selector: 'bad-booking-request-action-modal',
  templateUrl: './booking-request-action-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RdsDialogModule,
    RdsButtonModule,
    RdsIconComponent,
    RdsFormFieldModule,
    ReactiveFormsModule,
  ],
})
export class BookingRequestActionModalComponent {
  readonly #rdsDialogRef =
    inject<RdsDialogRef<BookingRequestActionModalComponent>>(RdsDialogRef);

  protected readonly dialogConfig = inject(
    RDS_DIALOG_DATA
  ) as BookingRequestActionModalEnum;
  protected readonly BookingRequestActionModalEnum =
    BookingRequestActionModalEnum;
  protected rejectControl = new FormControl('', [Validators.required]);
  protected cancelControl = new FormControl('', [Validators.required]);
  protected approveControl = new FormControl('');

  protected buttonClickHandler(action: BookingRequestActionModalEnum) {
    if (action === BookingRequestActionModalEnum.Confirm) {
      this.#rdsDialogRef.close({ message: this.approveControl.value });
    }
    if (
      action === BookingRequestActionModalEnum.Reject &&
      this.rejectControl.valid
    ) {
      this.#rdsDialogRef.close({ message: this.rejectControl.value });
    }
    if (
      action === BookingRequestActionModalEnum.Cancel &&
      this.cancelControl.valid
    ) {
      this.#rdsDialogRef.close({ message: this.cancelControl.value });
    }
  }
}
