import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import {
  AuthService,
  LocationModel,
  LocationsApiResponseModel,
  RoomApiResponseModel,
  RoomAvailabilityApiResponseModel,
  RoomCalendarEventsModel,
  RoomDetailsApiResponseModel,
  RoomDetailsModel,
  RoomFiltersApiResponseModel,
  RoomFiltersModel,
  RoomFlatModel,
  RoomLayoutUploadApiRequestModel,
  RoomLayoutUploadApiResponseModel,
  RoomLayoutUploadModel,
  RoomLayoutsApiResponseModel,
  RoomLayoutsModel,
  RoomQueryParams,
  RoomQueryParamsApiModel,
  RoomSettingApiResponseModel,
  RoomSettingModel,
  RoomSettingsApiRequestModel,
  RoomsByEmailApiResponseModel,
  RoomsListApiResponseModel,
  RoomsListModel,
  RoomsModel,
  environment,
} from '@bookly/shared';

import { roomCalendarEventsConverter } from './rooms.converter';

@Injectable({
  providedIn: 'root',
})
export class RoomsApiService {
  readonly #authService = inject(AuthService);
  readonly #http = inject(HttpClient);
  readonly #roomsByIdsUrl = environment.shared.roomsByIdsApi;
  readonly #roomsByEmailUrl = environment.admin.roomsByEmailApi;
  readonly #getRoomLayoutsUrl = environment.admin.getRoomLayoutsApi;
  readonly #userEmail = this.#authService.user?.email;
  readonly #roomsUrl = environment.shared.roomsApi;
  readonly #roomSettingsUrl = environment.admin.roomSettingsApi;
  readonly #roomLayoutsUrl = environment.admin.roomLayoutsApi;
  readonly #roomAvailabilityUrl = environment.shared.roomAvailabilityApi;
  readonly #locationsUrl = environment.shared.locationsApi;
  readonly #filtersUrl = environment.user.roomFiltersApi;

  public getRooms(
    queryParams?: RoomQueryParamsApiModel
  ): Observable<RoomsListModel[]> {
    let params;
    if (queryParams) {
      params = buildParams(queryParams);
    }

    return this.#http.get<RoomsListApiResponseModel[]>(this.#roomsUrl, {
      params,
    });
  }

  public getRoomsByRoomIds(roomIds: string[]): Observable<RoomsModel[]> {
    return this.#http.post<RoomApiResponseModel[]>(this.#roomsByIdsUrl, [
      ...roomIds,
    ]);
  }

  public getRoomDetails(roomDexId: string): Observable<RoomDetailsModel> {
    return this.#http.get<RoomDetailsApiResponseModel>(
      this.#roomsUrl + roomDexId
    );
  }

  public getRoomSettings(roomDexId: string): Observable<RoomSettingModel> {
    return this.#http.get<RoomSettingApiResponseModel>(
      this.#roomSettingsUrl + roomDexId
    );
  }

  public getRoomFilters(): Observable<RoomFiltersModel> {
    return this.#http.get<RoomFiltersApiResponseModel>(this.#filtersUrl);
  }

  public updateRoomSettings(
    roomDexId: string,
    request: RoomSettingsApiRequestModel
  ): Observable<RoomSettingModel> {
    return this.#http.put<RoomSettingApiResponseModel>(
      this.#roomSettingsUrl + roomDexId,
      request
    );
  }

  public getRoomsByEmail(): Observable<RoomsByEmailApiResponseModel> {
    return this.#http.get<RoomsByEmailApiResponseModel>(
      this.#roomsByEmailUrl + this.#userEmail
    );
  }

  public uploadLayout(
    layout: RoomLayoutUploadApiRequestModel
  ): Observable<RoomLayoutUploadModel> {
    return this.#http.post<RoomLayoutUploadApiResponseModel>(
      this.#roomLayoutsUrl,
      layout
    );
  }

  public uploadLayoutFile(fileUrl: string, file: File): Observable<never> {
    return this.#http.put<never>(fileUrl, file);
  }

  public deleteLayout(layoutId: string): Observable<never> {
    return this.#http.delete<never>(this.#roomLayoutsUrl + '/' + layoutId);
  }

  public getLayouts(roomDexId: string): Observable<RoomLayoutsModel[]> {
    return this.#http.get<RoomLayoutsApiResponseModel[]>(
      this.#getRoomLayoutsUrl + roomDexId + '/layouts'
    );
  }

  public getRoomAvailability(
    calendarId: string,
    dateFrom: string,
    dateTo: string
  ): Observable<RoomCalendarEventsModel[]> {
    return this.#http
      .get<RoomAvailabilityApiResponseModel>(
        this.#roomAvailabilityUrl + calendarId,
        {
          params: { to_date: dateTo, from_date: dateFrom },
        }
      )
      .pipe(map(roomCalendarEventsConverter));
  }

  public getLocations(): Observable<LocationModel[]> {
    return this.#http.get<LocationsApiResponseModel[]>(this.#locationsUrl);
  }

  public getFlatRooms(
    queryParams?: RoomQueryParams
  ): Observable<RoomFlatModel[]> {
    const params = queryParams ? buildParams(queryParams) : new HttpParams();
    return this.#http.get<RoomFlatModel[]>(this.#roomsUrl, {
      params: params,
    });
  }
}

export function buildParams(queryParams: object): HttpParams {
  let params = new HttpParams();
  Object.entries(queryParams)
    .filter((entry): entry is [string, string] => entry[1] !== undefined)
    .forEach(([key, value]) => {
      params = params.set(key, value);
    });
  return params;
}
