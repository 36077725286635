import { DatePipe, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import {
  RdsAvatarComponent,
  RdsAvatarImageDirective,
  RdsButtonModule,
  RdsCheckboxModule,
  RdsDatepickerModule,
  RdsFormFieldModule,
  RdsIconComponent,
  RdsPopoverModule,
  RdsTimepickerClearComponent,
  RdsTimepickerComponent,
  RdsTimepickerInputDirective,
} from '@rds/angular-components';

import {
  BookingRequestDetailsViewModel,
  BookingStatusEnum,
  RoomBookingFormService,
  RoomLayoutsModel,
} from '@bookly/shared';

import { BookingRequestService } from '../../services/booking-request.service';

@Component({
  selector: 'bad-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RdsFormFieldModule,
    NgOptimizedImage,
    RdsAvatarComponent,
    RdsAvatarImageDirective,
    RdsIconComponent,
    ReactiveFormsModule,
    RdsDatepickerModule,
    RdsButtonModule,
    RdsPopoverModule,
    RdsCheckboxModule,
    DatePipe,
    RdsTimepickerClearComponent,
    RdsTimepickerComponent,
    RdsTimepickerInputDirective,
  ],
  providers: [RoomBookingFormService],
})
export class EventDetailsComponent implements OnInit {
  @Input() public set eventDetails(
    value: BookingRequestDetailsViewModel | null
  ) {
    if (value && value !== this.eventDetail) {
      this.eventDetail = value;
      this.#roomBookingFormService.setFormValues(value.bookingDetails);
      this.#roomBookingFormService.setRoomDataValidators(value.roomDetails);
    }
  }
  @Input() public set roomLayouts(value: RoomLayoutsModel[]) {
    if (value && value.length > 0 && this.eventDetail) {
      this.layouts.set(value);
      this.#roomBookingFormService.setRoomLayoutsValidators(
        value,
        this.eventDetail.bookingDetails.roomLayoutUUID
      );
    }
  }
  @Output() public isEditModeEnabled = new EventEmitter<boolean>();

  readonly #bookingRequestService = inject(BookingRequestService);
  readonly #roomBookingFormService = inject(RoomBookingFormService);
  readonly #destroyRef = inject(DestroyRef);
  protected eventDetail!: BookingRequestDetailsViewModel;
  protected readonly layouts = signal<RoomLayoutsModel[]>([]);
  protected readonly minDate: Date = new Date();
  protected readonly isEditMode = this.#roomBookingFormService.isEditMode;
  protected readonly BookingStatusEnum = BookingStatusEnum;

  protected readonly bookingDetailsForm =
    this.#roomBookingFormService.roomBookingForm;

  public ngOnInit() {
    this.#roomBookingFormService.setEditMode(false);
    this.#roomBookingFormService.layoutsControlValueChange
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  }

  protected switchToEditMode() {
    this.#roomBookingFormService.setEditMode(true);
    this.isEditModeEnabled.emit(true);
  }

  protected save() {
    this.bookingDetailsForm.updateValueAndValidity();
    if (this.bookingDetailsForm.valid) {
      this.#bookingRequestService
        .updateBookingRequest({
          id: this.eventDetail.bookingDetails.id,
          ...this.#roomBookingFormService.createRoomBookingRequestBody(),
        })
        .subscribe(() => {
          this.#roomBookingFormService.setEditMode(false);
          this.isEditModeEnabled.emit(false);
        });
    }
  }

  protected cancel() {
    this.#roomBookingFormService.setEditMode(false);
    this.isEditModeEnabled.emit(false);
    if (this.eventDetail) {
      this.#roomBookingFormService.setFormValues(
        this.eventDetail.bookingDetails
      );
    }
  }
}
