import { EnvironmentModel } from './environment.model';

const apiUrl = 'https://bokly-dev.aws.cloud.roche.com';

export const environment: EnvironmentModel = {
  hiSiteUrl: 'https://com-infsoft-wpe-roche-webapp-bookly.azurewebsites.net',
  firebaseConfig: {
    projectId: 'bookly-fad0a',
    appId: '1:720164160803:web:58459cf107c684420cb896',
    storageBucket: 'bookly-fad0a.firebasestorage.app',
    apiKey: 'AIzaSyBGur9YBBJeFYyphAWFDUOc_7y3cpAWMV0',
    authDomain: 'bookly-fad0a.firebaseapp.com',
    messagingSenderId: '720164160803',
    measurementId: 'G-JBXGRGXJN0',
  },
  vapidKey:
    'BHwdVS9hpZdbgVMqWqdPgFz4cehr7QMt4_xi1y8tlgr9lV4hFDKcmRnpiF0kvTNCx_jU4VTzQpx3wOOq4EjbL8c',
  shared: {
    development: true,
    apiUrl,
    authApi: `${apiUrl}/auth/v1/auth`,
    logoutApi: `${apiUrl}/auth/v1/logout`,
    googleAuthUrl:
      'https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount',
    googleClientId:
      '1038809804201-f9ms8l26e90mnnpdgqn6m5ca58md1doq.apps.googleusercontent.com',
    roomsApi: `${apiUrl}/rooms/v1/rooms/`,
    chatMessages: `${apiUrl}/chat/v1/messages/`,
    roomsByIdsApi: `${apiUrl}/rooms/v1/rooms/items`,
    roomAvailabilityApi: `${apiUrl}/freebusy/v1/unavailabilities/`,
    locationsApi: `${apiUrl}/rooms/v1/filterparams/locations`,
    deviceTokenApi: `${apiUrl}/endpoints/v1/endpoints/`,
  },
  user: {
    roomBookingApi: `${apiUrl}/roombookingapi/v1/roombookings`,
    myBookingsApi: `${apiUrl}/roombookingapi/v1/roombookings/mybookings/`,
    roomFiltersApi: `${apiUrl}/rooms/v1/filterparams`,
    notificationsApi: `${apiUrl}/notifications/v1/notifications-user-aware`,
  },
  admin: {
    roomSettingsApi: `${apiUrl}/rooms/v1/managespaces/`,
    bookingDetailsApi: `${apiUrl}/roombookingadminapi/v1/roombookings`,
    roomBookingsApi: `${apiUrl}/roombookingadminapi/v1/roombookings/host`,
    approveRoomBookingApi: `${apiUrl}/roombookingadminapi/v1/roombookings/approve`,
    rejectRoomBookingApi: `${apiUrl}/roombookingadminapi/v1/roombookings/reject`,
    cancelRoomBookingApi: `${apiUrl}/roombookingadminapi/v1/roombookings/cancel`,
    getRoomLayoutsApi: `${apiUrl}/roomlayoutmanagementapi/v1/rooms/`,
    roomsByEmailApi: `${apiUrl}/membergroupsmanagement/v1/rooms/`,
    roomLayoutsApi: `${apiUrl}/roomlayoutmanagementapi/v1/roomlayouts`,
    groupsApi: `${apiUrl}/membergroupsmanagement/v1/groups`,
    membersApi: `${apiUrl}/membergroupsmanagement/v1/members`,
    groupRoomsApi: `${apiUrl}/membergroupsmanagement/v1/rooms/`,
    roomsCalendarApi: `${apiUrl}/roombookingadminapi/v1/roombookings/host/calendar`,
    notificationsApi: `${apiUrl}/notifications/v1/notifications/`,
  },
};
