import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  RDS_DIALOG_DATA,
  RdsButtonModule,
  RdsDialogModule,
} from '@rds/angular-components';

export interface ConfirmationData {
  header?: string;
  message?: string;
  reject?: string;
  accept?: string;
}

@Component({
  selector: 'bsh-confirmation',
  imports: [RdsDialogModule, RdsButtonModule],
  templateUrl: './confirmation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationComponent {
  protected readonly data = inject(RDS_DIALOG_DATA) as ConfirmationData;
}
