import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  RdsAvatarComponent,
  RdsAvatarIconDirective,
  RdsAvatarImageDirective,
  RdsCustomIconComponent,
  RdsIconComponent,
} from '@rds/angular-components';

import {
  BookingRequestDetailsModel,
  RoomServicesApiResponseEnum,
  SERVICE_MODEL_ARRAY,
  ServiceViewModel,
} from '../../../booking-request';
import { EventDateTimePipe } from '../../../core';

@Component({
  selector: 'bsh-booking-summary',
  imports: [
    RdsIconComponent,
    EventDateTimePipe,
    RdsAvatarComponent,
    RdsAvatarImageDirective,
    RdsAvatarIconDirective,
    RdsCustomIconComponent,
    NgOptimizedImage,
  ],
  templateUrl: './booking-summary.component.html',
  styleUrl: './booking-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingSummaryComponent {
  @Input() set bookingDetails(details: BookingRequestDetailsModel) {
    this.selectedServices = [];
    this.details = details;
    this.services = structuredClone(SERVICE_MODEL_ARRAY);
    this.details.services?.forEach(service => {
      const currentValue = this.services.get(service.serviceType);
      if (currentValue) {
        this.services.set(service.serviceType, currentValue);
        if (service.url) {
          currentValue.description = service.url;
          currentValue.isUrl = true;
        }
        this.selectedServices.push(currentValue);
      }
    });
  }
  protected details: BookingRequestDetailsModel | null = null;
  protected services: Map<RoomServicesApiResponseEnum, ServiceViewModel> =
    structuredClone(SERVICE_MODEL_ARRAY);
  protected selectedServices: ServiceViewModel[] = [];
}
