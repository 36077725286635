import { BookingStatusEnum } from '@bookly/shared';

export interface NotificationsApiResponseModel {
  userEmail: string;
  notificationId: number;
  notificationTitle: string;
  notificationContent: string;
  attributes: Attributes;
  status: NotificationStatusApiResponseEnum;
  sourceId: SourceId;
  sourceService: NotificationSourceTypeApiResponseEnum;
  destinationFrontend: DestinationFrontendApiResponseEnum;
  createdAt: string;
  expirationTimeEpoch: number;
  emailStatus: string;
  emailSentAt: string;
}

export interface ChatAttributes {
  chatMessage: string;
  roomDexId: string;
  bookingId: number;
  bookingLink: string;
  roomName: string;
  bookingStatus: string;
  bookingStatusEnum: BookingStatusEnum;
}

export type Attributes = ChatAttributes & RoomBookingAttributes;

export interface SourceId {
  idName: string;
  idValue: string;
}

export interface RoomBookingAttributes {
  temIncluded: string;
  reason: string;
  address: string;
  cateringIncluded: string;
  cateringLink: string;
  timeTo: string;
  roomDexId: string;
  bookingId: number;
  roomName: string;
  timeFrom: string;
  bookingLink: string;
  eventName: string;
  bookingStatus: string;
  furnitureTeamLink: string;
  furnitureTeamIncluded: string;
  roomLockingServiceIncluded: string;
  numberOfPeople: number;
}

export enum NotificationStatusApiResponseEnum {
  Unread = 'UNREAD',
  Read = 'READ',
}

export enum NotificationSourceTypeApiResponseEnum {
  RoomBooking = 'ROOM_BOOKING',
  Chat = 'CHAT',
}

export enum DestinationFrontendApiResponseEnum {
  Admin = 'ADMIN',
  User = 'USER',
}
