import { NotificationsModel, statusConverter } from '@bookly/shared';

import { NotificationsApiResponseModel } from '../model/notifications-api.model';

export function notificationConverter(
  notifications: NotificationsApiResponseModel[]
): NotificationsModel[] {
  return notifications.map(notification => ({
    ...notification,
    attributes: {
      ...notification.attributes,
      status: statusConverter(notification.attributes.bookingStatusEnum),
    },
  }));
}
