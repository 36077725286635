@if (notification) {
  <div
    class="notification"
    role="button"
    tabindex="0"
    [ngClass]="notification.status"
    (keydown)="handleNotificationClick()"
    (click)="handleNotificationClick()">
    <div class="notification-header">
      @if (notification.sourceService === NotificationSourceTypeEnum.Chat) {
        <p class="ui-heading-4--m">Message received</p>
      }
      @if (
        notification.sourceService === NotificationSourceTypeEnum.RoomBooking
      ) {
        <p class="ui-heading-4--m">Booking modification/Status change</p>
      }
      @if (notification.status === NotificationStatusEnum.Unread) {
        <rds-badge size="l" label="" type="dot"></rds-badge>
      }
    </div>
    <p>
      Booking ID: {{ notification.attributes.bookingId }}
      @if (notification.sourceService !== NotificationSourceTypeEnum.Chat) {
        | Status:
        {{ notification.attributes.status.text }}
      }
    </p>
    <p>
      {{
        notification.attributes.chatMessage
          ? notification.attributes.chatMessage
          : notification.notificationContent
      }}
    </p>
    <p class="ui-label-s">
      {{ notification.createdAt | date: 'dd MMM yyyy HH:mm' }}
    </p>
  </div>
}
