import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  inject,
} from '@angular/core';
import {
  RdsBadgeDirective,
  RdsButtonModule,
  RdsDialogService,
  RdsDropdownDirective,
  RdsDropdownModule,
  RdsIconComponent,
  RdsProgressSpinnerModule,
} from '@rds/angular-components';

import { NotificationComponent } from '../../components/notification/notification.component';
import { NotificationsService } from '../../services/notifications.service';
import { AllNotificationsDialogComponent } from '../all-notifications-dialog/all-notifications-dialog.component';

@Component({
  selector: 'bsh-notifications-dropdown',
  imports: [
    RdsDropdownModule,
    RdsIconComponent,
    RdsBadgeDirective,
    RdsButtonModule,
    RdsProgressSpinnerModule,
    NotificationComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './notifications-dropdown.component.html',
  styleUrl: './notifications-dropdown.component.scss',
})
export class NotificationsDropdownComponent {
  readonly #notificationsService = inject(NotificationsService);
  readonly #dialogService = inject(RdsDialogService);
  readonly unreadCount = this.#notificationsService.unreadCount;
  readonly latestNotifications = this.#notificationsService.latestNotifications;
  readonly isLoading = this.#notificationsService.isLoading;

  @ViewChild(RdsDropdownDirective)
  notificationsDropdown!: RdsDropdownDirective;

  protected showAllNotifications() {
    this.#dialogService.open(AllNotificationsDialogComponent, {
      disableClose: true,
      size: 'm',
      maxHeight: '100%',
    });
    this.notificationsDropdown.hide();
  }

  protected markAllAsRead() {
    this.#notificationsService.markAllNotificationAsRead().subscribe();
  }
}
