import { RdsBadgeColor } from '@rds/angular-components';

import {
  ChatAttributes,
  DestinationFrontendApiResponseEnum,
  NotificationSourceTypeApiResponseEnum,
  NotificationStatusApiResponseEnum,
  NotificationsApiResponseModel,
  RoomBookingAttributes,
} from './notifications-api.model';
import { BookingStatusEnum } from '../../booking-request';

export interface NotificationsModel
  extends Omit<NotificationsApiResponseModel, 'attributes'> {
  attributes: ExtendedChatAttributes & RoomBookingAttributes;
}

export interface ExtendedChatAttributes
  extends Omit<ChatAttributes, 'bookingStatusEnum' | 'bookingStatus'> {
  status: {
    value: BookingStatusEnum;
    text: string;
    color: RdsBadgeColor;
  };
}

export type NotificationStatusEnum = NotificationStatusApiResponseEnum;
export const NotificationStatusEnum = { ...NotificationStatusApiResponseEnum };

export type NotificationSourceTypeEnum = NotificationSourceTypeApiResponseEnum;
export const NotificationSourceTypeEnum = {
  ...NotificationSourceTypeApiResponseEnum,
};

export type DestinationFrontendEnum = DestinationFrontendApiResponseEnum;
export const DestinationFrontendEnum = {
  ...DestinationFrontendApiResponseEnum,
};
